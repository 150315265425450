import HttpService from "@/services/HttpService";

const MainService = {
  async loginIris(usuario, palabraSecreta) {
    const cargaUtil = { usuario, palabraSecreta };
    return await HttpService.post("/login_iris.php", cargaUtil);
  },
  async logout() {
    return await HttpService.post("/logout.php");
  },
  async BuscarFecha(fecha1, fecha2) {
    const cargaUtil = { fecha1, fecha2 };
    return await HttpService.post("/BuscarFecha.php", cargaUtil);
  },
  async ReportePendientes(dato) {
    const cargaUtil = { dato };
    return await HttpService.post("/Reporte.php", cargaUtil);
  },
  async ReporteConciliados(dato) {
    const cargaUtil = { dato };
    return await HttpService.post("/ReporteConciliados.php", cargaUtil);
  },
  async ReporteGrupo(dato) {
    const cargaUtil = { dato };
    return await HttpService.post("/ReporteGrupo.php", cargaUtil);
  },

  async obtenerNewDatos() {
    return await HttpService.post("/obtenerNewDatos.php");
  },

  async obtenerTablaFiltrada() {
    return await HttpService.post("/obtenerTablaFiltrada.php");
  },
  async obtenerCXSFiltrados() {
    return await HttpService.post("/obtenerCXSFiltrados.php");
  },
  async EliminarDatos() {
    return await HttpService.post("/EliminarDatos.php");
  },

  async EliminarDocument(id) {
    return await HttpService.post("/EliminarDocument.php", {
      id,
    });
  },
  async ObtenerMatches() {
    return await HttpService.post("/ObtenerMatches.php");
  },
  async ObtenerGroups() {
    return await HttpService.post("/ObtenerGroups.php");
  },
  async ObtenerDataGroup() {
    return await HttpService.post("/ObtenerDataGroup.php");
  },
  async EliminarDeBD() {
    return await HttpService.post("/EliminarDeBD.php");
  },

  async BuscarAgente(option) {
    return await HttpService.post("/BuscarAgente.php", {
      option,
    });
  },
  async TraspasarCXS() {
    return await HttpService.post("/TraspasarCXS.php");
  },

  async BuscarEnMitad(array) {
    return await HttpService.post("/BuscarEnMitad.php", {
      array,
    });
  },
  async EliminarBag() {
    return await HttpService.post("/EliminarBag.php");
  },

  async EliminarPNR() {
    return await HttpService.post("/EliminarPNR.php");
  },

  async EliminarRepetidos() {
    return await HttpService.post("/EliminarRepetidos.php");
  },

  async EliminarCodeBag(datos) {
    return await HttpService.post("/EliminarCodeBag.php", {
      datos,
    });
  },
  async EliminarAgente(id) {
    return await HttpService.post("/EliminarAgente.php", {
      id,
    });
  },
  async obtenerAgencias() {
    return await HttpService.post("/obtenerAgencias.php");
  },
  async obtenerCodigosBag() {
    return await HttpService.post("/obtenerCodigosBag.php");
  },
  async obtenerAgentes() {
    return await HttpService.post("/obtenerAgentes.php");
  },
  async obtenerDKAgencias() {
    return await HttpService.get("/listAgencias.php");
  },
  async obtenerContador() {
    return await HttpService.post("/obtenerContador.php");
  },
  async obtenerBoleto(boleto) {
    return await HttpService.post("/obtenerBoleto.php", {
      boleto,
    });
  },
  async ContadorMatches() {
    return await HttpService.post("/ContadorMatches.php");
  },
  async GuardarDatos(array) {
    return await HttpService.post("/GuardarDatos.php", {
      array,
    });
  },
  async GuardarBoletos(array) {
    return await HttpService.post("/GuardarBoletos.php", {
      array,
    });
  },
  async GuardarGrupos(array) {
    return await HttpService.post("/GuardarGrupos.php", {
      array,
    });
  },

  async ConciliarTodos(array) {
    return await HttpService.post("/ConciliarTodos.php", {
      array,
    });
  },
  async NuevaException(nombre, dk) {
    return await HttpService.post("/NuevaException.php", {
      nombre: nombre,
      dk: dk,
    });
  },
  async NuevoCodeBag(concepto, codigo) {
    return await HttpService.post("/NuevoCodeBag.php", {
      concepto: concepto,
      codigo: codigo,
    });
  },
  async NuevoAgente(clave, usuario) {
    return await HttpService.post("/NuevoAgente.php", {
      clave: clave,
      usuario: usuario,
    });
  },
  async Eliminar(id) {
    return await HttpService.post("/Eliminar.php", {
      id,
    });
  },
  async cambiarEstado(id, opcion, nuevoVal) {
    return await HttpService.post("/cambiarDatosBoletos.php", {
      id,
      opcion,
      nuevoVal,
    });
  },

  async cambiarBoleto(boleto, opcion, nuevoVal) {
    return await HttpService.post("/cambiarBoleto.php", {
      boleto,
      opcion,
      nuevoVal,
    });
  },
  async AddBoleto(boleto) {
    return await HttpService.post("/AddBoleto.php", {
      boleto,
    });
  },
  async crearFacturas(props) {
    return await HttpService.post("/crearFacturas.php", { data: props });
  },
  async crearFactura(props) {
    return await HttpService.post("/crearFactura.php", { id: props });
  },
  async MandarFecha(FechaSolicitada) {
    const cargaUtil = { FechaSolicitada };
    return await HttpService.post("/MandarFecha.php", cargaUtil);
  },
};

export default MainService;
