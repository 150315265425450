<template>
  <div class="box">
    Resultados: {{ DataTable.length }}
    <b-field>
      <b-button
        icon-left="download"
        outlined
        type="is-success"
        @click="CrearReporte()"
        >Descargar</b-button
      >

      <b-button
        icon-left="list"
        outlined
        v-if="DataTable.length > 1"
        type="is-info"
        @click="crearFacturasMasivas()"
        >Facturar Todo</b-button
      >

      <b-button
        icon-left="trash"
        outlined
        v-if="DataTable.length > 1"
        type="is-danger"
        @click="EliminarTabla(props)"
        >Limpiar Tabla</b-button
      >
    </b-field>

    <div class="rows">
      <div class="row">
        <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
      </div>
      <div class="TBL1">
        <b-table :data="TableFiltrada" detailed>
          <b-table-column
            field="clave_cliente"
            label="DK"
            sortable
            v-slot="props"
          >
            {{ props.row[1].clave_cliente }}
          </b-table-column>

          <b-table-column
            field="nombre_cliente"
            label="Nombre del cliente"
            sortable
            v-slot="props"
          >
            {{ props.row[0][0].nombre_cliente }}
          </b-table-column>

          <b-table-column label="DSE3/FS4" sortable v-slot="props">
            {{ obtenerDSE3(props.row[0]) }}
          </b-table-column>

          <b-table-column
            label="Actualizar"
            v-if="checkedRows.length > 0"
            centerd
          >
            <a class="button is-light" @click="ConciliarUno()" target="_blank">
              <b-icon icon="floppy-o" type="is-success"></b-icon>
            </a>

            <a class="button is-light" target="_blank" @click="crearFacturas()">
              <b-icon icon="file" type="is-info"></b-icon>
            </a>
          </b-table-column>

          <template #detail="props">
            <td></td>
            <td></td>
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <b-table
                    :data="props.row[0]"
                    :checked-rows.sync="checkedRows"
                    checkable
                  >
                    <b-table-column
                      field="serie"
                      label="serie"
                      sortable
                      v-slot="props"
                    >
                      {{ props.row.serie }}
                    </b-table-column>

                    <b-table-column
                      field="factura"
                      label="factura"
                      sortable
                      v-slot="props"
                    >
                      {{ props.row.factura }}
                    </b-table-column>

                    <b-table-column
                      field="pasajero"
                      label="pasajero"
                      sortable
                      v-slot="props"
                    >
                      {{ props.row.pasajero }}
                    </b-table-column>

                    <b-table-column
                      field="ruta"
                      label="ruta"
                      sortable
                      v-slot="props"
                    >
                      {{ props.row.ruta }}
                    </b-table-column>

                    <b-table-column
                      field="linea_aerea"
                      label="linea_aerea"
                      sortable
                      v-slot="props"
                    >
                      {{ props.row.linea_aerea }}
                    </b-table-column>

                    <b-table-column
                      field="boleto"
                      label="boleto"
                      sortable
                      v-slot="props"
                    >
                      {{ props.row.boleto }}
                    </b-table-column>

                    <b-table-column
                      field="fecha"
                      label="fecha_emision"
                      sortable
                      v-slot="props"
                    >
                      {{ props.row.fecha }}
                    </b-table-column>

                    <b-table-column
                      field="pnr"
                      label="pnr"
                      sortable
                      v-slot="props"
                    >
                      {{ props.row.pnr }}
                    </b-table-column>
                    <b-table-column
                      field="usuario"
                      label="Agente"
                      sortable
                      v-slot="props"
                    >
                      <strong>{{ props.row.usuario }}</strong>
                    </b-table-column>
                    <b-table-column
                      field="id"
                      label="Crear Factura"
                      sortable
                      v-slot="props"
                    >
                      <a
                        class="button is-light"
                        target="_blank"
                        @click="crearFactura(props.row)"
                      >
                        <b-icon icon="file" type="is-danger"></b-icon>
                      </a>
                    </b-table-column>
                    <template #bottom-left>
                      <b>Total checked</b>: {{ checkedRows.length }}
                    </template>
                  </b-table>
                  <b-tooltip
                    v-if="checkedRows.length > 0"
                    label="Guardar Cambios"
                  >
                    <b-button
                      icon-left="floppy-o"
                      type="is-success"
                      @click="ConciliarUno()"
                      >Guardar</b-button
                    ></b-tooltip
                  >
                </div>
              </div>
            </article>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import MainServices from "@/services/MainServices";
import Constantes from "@/Constantes";
export default {
  data() {
    return {
      isLoading: false,
      isFullPage: true,
      DataTable: [],
      TableFiltrada: [],
      checkedRows: [],
    };
  },
  mounted() {
    this.obtenerTablaFiltrada();
  },
  methods: {
    async crearFacturasMasivas() {
      try {
        this.isLoading = true;
        const resultado = await MainServices.crearFacturas(this.DataTable);
        console.log(resultado);
        this.message("Se han generado los archivos AIR", "is-success");
      } catch (error) {
        console.log(error);
        this.message("Hubo un error al generar las facturas.", "is-danger");
      } finally {
        this.isLoading = false;
        this.obtenerTablaFiltrada();
      }
    },
    async crearFacturas() {
      try {
        this.isLoading = true;
        const resultado = await MainServices.crearFacturas(this.checkedRows);
        console.log(resultado);
      } catch (e) {
        console.log(e);
      } finally {
        this.message("Se han generado los archivo AIR", "is-success");
        this.isLoading = false;
        this.obtenerTablaFiltrada();
      }
    },
    async crearFactura(dato) {
      try {
        this.isLoading = true;
        const resultado = await MainServices.crearFactura(dato.id);
        console.log(resultado);
        this.checkedRows = dato;
      } catch (e) {
        console.log(e);
      } finally {
        this.message("Se ha generado el archivo AIR", "is-success");
        this.isLoading = false;
        this.obtenerTablaFiltrada();
      }
    },
    async ConciliarUno() {
      this.isLoading = true;
      let resultado = await MainServices.ConciliarTodos(this.checkedRows);
      this.isLoading = false;
      this.checkedRows = [];
      this.obtenerTablaFiltrada();
      if (resultado) {
        this.message("Se actualizo la tabla de CXS", "is-success");
      }
    },

    obtenerDSE3(row) {
      let dse3 = 0;
      let fs4 = 0;

      for (const element of row) {
        if (element.serie === "DSE3") {
          dse3++;
        } else {
          fs4++;
        }
      }

      return "DSE3: " + dse3 + " || FS4: " + fs4;
    },

    async CrearReporte() {
      try {
        const respuesta = await MainServices.ReportePendientes(this.DataTable);
        if (respuesta) {
          this.message("Archivo creado correctamente", "is-success");

          let date = new Date();
          let output =
            "Pendientes " +
            String(date.getDate()).padStart(2, "0") +
            "/" +
            String(date.getMonth() + 1).padStart(2, "0") +
            "/" +
            date.getFullYear();
          const url =
            Constantes.URL_SERVIDOR + "/descargar_Reporte.php?date=" + output;
          window.open(url);
        } else {
          this.message("Error al crear archivo", "is-danger");
        }
      } catch (e) {
        console.log(e);
        this.message("Error al crear archivo: " + e, "is-danger");
      }
    },
    async obtenerNewDatos() {
      this.isLoading = true;
      this.DataTable = await MainServices.obtenerNewDatos();
      this.isLoading = false;
    },

    async obtenerTablaFiltrada() {
      this.isLoading = true;
      this.TableFiltrada = await MainServices.obtenerTablaFiltrada();

      this.obtenerNewDatos();
      this.isLoading = false;
    },
    async Eliminar(id) {
      const resp = await MainServices.EliminarDocument(id);
      console.log(resp);
      this.obtenerTablaFiltrada();
      this.message("Se elimino el registro", "is-danger");
    },
    async EliminarTabla() {
      const resp = await MainServices.EliminarDatos();
      console.log(resp);
      this.message("Sin pendientes ahora", "is-success");
      location.reload();
    },

    message(mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo,
      });
    },
  },
};
</script>
