<template>
  <section id="app">
    <router-view></router-view>
  </section>
</template>

<script>
import { EventBus } from "@/EventBus";
export default {
  name: "App",
  components: {},
  data() {
    return {
      isLoading: false,
      isFullPage: true,
    };
  },
  mounted() {
    // Escuchar el evento navegarHacia
    EventBus.$on("navegarHacia", (ruta) => {
      // Redirigir a la ruta deseada
      this.$router.push({ name: ruta });
    });
  },
  beforeDestroy() {
    // Eliminar el listener cuando el componente se destruye
    EventBus.$off("navegarHacia");
  },
  methods: {
    openLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 10 * 1000);
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
}
</style>
