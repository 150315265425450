<template>
  <div>
    <h1 class="is-size-1">Estamos cerrando sesión</h1>
    <p>Espera...</p>
    <p>
      Si estás viendo esto por mucho tiempo, sería bueno refrescar la página.
      Esto suele pasar cuando no cierras sesión antes de cerrar el navegador
    </p>
  </div>
</template>

<script>
import MainServices from "@/services/MainServices";
import EventBus from "@/EventBus";

export default {
  name: "LogoutComponent",
  async mounted() {
    EventBus.eliminarUsuario(); // Elimina el usuario del EventBus y LocalStorage
    await MainServices.logout(); // Llama a la función de logout del servicio

    // Navega al componente LoginCXS sin errores redundantes
    this.$router.push({ name: "LoginCXS" }).catch((err) => {
      if (err.name !== "NavigationDuplicated") {
        throw err;
      }
    });

    // Muestra un mensaje de despedida
    this.message("Hasta pronto", "is-success");
  },
  methods: {
    message(mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo,
      });
    },
  },
};
</script>
