import Vue from "vue";
import Router from "vue-router";
import Inicio from "@/components/Inicio";
import AdminXLSX from "@/components/AdminXLSX";
import LoginCXS from "@/components/LoginCXS";
import Logout from "@/components/Logout";
import { EventBus } from "@/EventBus"; // Importamos el EventBus

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      name: "LoginCXS",
      component: LoginCXS,
    },
    {
      path: "/Logout",
      name: "Logout",
      component: Logout,
    },
    {
      path: "/Inicio",
      name: "Inicio",
      component: Inicio,
      meta: { requiresAuth: true }, // Esta ruta requiere autenticación
    },
    {
      path: "/AdminXLSX",
      name: "AdminXLSX",
      component: AdminXLSX,
      meta: { requiresAuth: true }, // También requiere autenticación
    },
  ],
});

// Agregamos una guardia de navegación
router.beforeEach((to, from, next) => {
  const isAuthenticated =
    Object.keys(EventBus.obtenerUsuario() || {}).length > 0; // Chequeamos si hay usuario en el EventBus

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated
  ) {
    // Si la ruta requiere autenticación y el usuario no está autenticado
    next({ name: "LoginCXS" }); // Redirigimos al Login
  } else {
    next(); // Continuamos a la ruta
  }
});

export default router;
