<template>
  <div
    style="
      text-align: center;
      align-items: center;
      display: flex;
      flex-direction: column;
    "
  >
    <!-- Background image -->
    <div style="width: 44vh">
      <img alt="fondo" src="@/assets/Fondo.jpg" />
    </div>
    <!-- Background image -->

    <div
      class="box"
      style="
        margin-top: -7vh;
        background: hsla(0, 0%, 100%, 0.8);
        backdrop-filter: blur(5px);
      "
    >
      <div class="card-body py-5 px-md-5">
        <div>
          <div>
            <h1 class="title has-text-grey">Bienvenido</h1>
            <p class="subtitle has-text-grey">
              Sistema de Facturación de cargos por servicio
            </p>
            <form>
              <!-- 2 column grid layout with text inputs for the first and last names -->

              <b-field label="Usuario" label-position="on-border">
                <b-input
                  :loading="cargando"
                  required
                  ref="fieldUser"
                  id="login"
                  class="fadeIn second"
                  name="login"
                  placeholder="Usuario de IRIS"
                  type="text"
                  v-model="user"
                ></b-input>
              </b-field>
              <b-field label="Contraseña" label-position="on-border">
                <b-input
                  :loading="cargando"
                  required
                  ref="fieldPalabraSecreta"
                  id="password"
                  class="fadeIn third"
                  name="login"
                  type="password"
                  placeholder="Contraseña de IRIS"
                  v-model="palabraSecreta"
                  password-reveal
                ></b-input>
              </b-field>
              <b-field>
                <b-button
                  :loading="cargando"
                  @click="iniciarSesion()"
                  type="is-success"
                  >Iniciar sesión</b-button
                >
              </b-field>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainServices from "@/services/MainServices";
import { EventBus } from "@/EventBus";

export default {
  name: "Login_Firmas",
  data: () => ({
    departamento: [],
    user: "",
    palabraSecreta: "",
    cargando: false,
  }),

  methods: {
    obtenerUsuarioDeLocalStorage() {
      this.departamento = EventBus.obtenerUsuario();
    },
    async iniciarSesion() {
      const fields = ["fieldUser", "fieldPalabraSecreta"];
      for (const field of fields) {
        if (!this.$refs[field].checkHtml5Validity()) {
          return;
        }
      }
      try {
        this.cargando = true;
        const usuarioLogueado = await MainServices.loginIris(
          this.user,
          this.palabraSecreta
        );

        if (usuarioLogueado.status) {
          this.message("Bienvenido", "is-success");
          EventBus.establecerUsuario(usuarioLogueado);

          // Navegar directamente utilizando $router con manejo de errores
          if (this.$router.currentRoute.name !== "Inicio") {
            this.$router.push({ name: "Inicio" }).catch((err) => {
              if (err.name !== "NavigationDuplicated") {
                throw err;
              }
            });
          }

          // Limpia los campos
          this.user = this.palabraSecreta = "";
        } else {
          this.message(
            "Usuario o contraseña incorrectos - Favor de contactarse con su ejecutivo",
            "is-danger"
          );
        }
      } catch (e) {
        this.message("Error iniciando sesión: " + e, "is-danger");
        console.log(e);
      } finally {
        this.cargando = false;
      }
    },
    message(mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo,
      });
    },
  },
};
</script>

<style scoped>
.box {
  border-radius: 25px;
  width: 44vh;
}
</style>
